import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import {Menu} from 'semantic-ui-react';
import './App.css';
import {ActionContent} from "./ActionContent";
import {LeverContent} from "./LeverContent";
import {Redirect, Switch} from "react-router";


class App extends Component {

    render() {
        return (
            <div className="app">
                <Menu>
                    <Menu.Item active>Lever</Menu.Item>
                </Menu>
                <Router>
                    <Switch>
                        <Route path="/actions/:actionId" exact component={ActionContent}/>
                        <Route path="/:leverId" exact component={LeverContent}/>
                        <Route path="/" exact render={props => null}/>
                        <Redirect to="/"/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
