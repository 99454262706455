import {ParseResult} from "bryx-cereal";
import * as request from "request";
import {Lever} from "../models/Lever";
import {Nothing} from "../models/Nothing";

export type ApiResult<T> = { success: true, value: T } | { success: false, message: string };

export class API {
    public static readonly apiRoot = "https://starscream.bryx911.com/lever-api";

    static resultFromResponse<T>(error: any, response: request.Response, parse: (o: any) => ParseResult<T>): ApiResult<T> {
        if (response != null && response.statusCode >= 200 && response.statusCode < 300) {
            const parseResult = parse(response.body && JSON.parse(response.body));
            if (parseResult.success == true) {
                return {success: true, value: parseResult.value};
            } else {
                return {success: false, message: parseResult.justification};
            }
        } else {
            return {success: false, message: error || "Unknown error"};
        }
    }

    static getLever(leverId: string, callback: (result: ApiResult<Lever>) => void) {
        request.get(`${API.apiRoot}/levers/${leverId}`, (error, response) => {
            callback(API.resultFromResponse(error, response, Lever.parse))
        });
    }

    static executeAction(actionId: string, callback: (result: ApiResult<Nothing>) => void) {
        request.post(`${API.apiRoot}/actions/${actionId}`, (error, response) => {
            callback(API.resultFromResponse(error, response, Nothing.parse))
        });
    }
}
