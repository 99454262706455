import React, {Component} from 'react';
import {RouteComponentProps} from "react-router";
import {Button, Message} from 'semantic-ui-react';
import {API} from "./utils/Api";

type ActionContentProps = RouteComponentProps<{actionId: string}>;

interface ActionContentState {
    actionStatus: {key: "ready"} | {key: "loading"} | {key: "error", message: string};
}

export class ActionContent extends Component<ActionContentProps, ActionContentState> {
    constructor(props: ActionContentProps) {
        super(props);
        this.state = {
            actionStatus: {key: "ready"},
        };
    }

    private executeAction() {
        if (this.state.actionStatus.key != "ready") {
            return;
        }
        this.setState({actionStatus: {key: "loading"}});
        API.executeAction(this.props.match.params.actionId, result => {
            if (result.success) {
                this.setState({actionStatus: {key: "ready"}});
            } else {
                this.setState({actionStatus: {key: "error", message: result.message}});
            }
        })
    }

    render() {
        const {actionStatus} = this.state;
        const parsedParams = new URL(window.location.href).searchParams;
        return (
            <div className="content">
                <Button primary
                        size="massive"
                        content={parsedParams.get("t") || "Trigger"}
                        loading={actionStatus.key == "loading"}
                        onClick={() => this.executeAction()}/>
                {actionStatus.key == "error" ? (
                    <Message negative content={actionStatus.message}/>
                ) : null}
            </div>
        );
    }
}
