import React, {Component} from 'react';
import {RouteComponentProps} from "react-router";
import {Button, Header, Loader, Message} from 'semantic-ui-react';
import {Lever, LeverAction} from "./models/Lever";
import {API} from "./utils/Api";

type LeverContentProps = RouteComponentProps<{leverId: string}>;

interface LeverContentState {
    loadingStatus: {key: "loading"} | {key: "ready", lever: Lever} | {key: "error", message: string};
    actionStatus: {key: "ready"} | {key: "loading", actionId: string} | {key: "error", message: string};
}

export class LeverContent extends Component<LeverContentProps, LeverContentState> {
    constructor(props: LeverContentProps) {
        super(props);
        this.state = {
            loadingStatus: {key: "loading"},
            actionStatus: {key: "ready"},
        };
    }

    componentDidMount() {
        const leverId = this.props.match.params.leverId;
        API.getLever(leverId, result => {
            if (result.success == true) {
                this.setState({loadingStatus: {key: "ready", lever: result.value}});
            } else {
                this.setState({loadingStatus: {key: "error", message: result.message}});
            }
        })
    }

    private executeAction(action: LeverAction) {
        if (this.state.actionStatus.key != "ready") {
            return;
        }
        this.setState({actionStatus: {key: "loading", actionId: action.id}});
        API.executeAction(action.id, result => {
            if (result.success == true) {
                this.setState({actionStatus: {key: "ready"}});
            } else {
                this.setState({actionStatus: {key: "error", message: result.message}});
            }
        })
    }

    render() {
        const {loadingStatus, actionStatus} = this.state;
        if (loadingStatus.key == "loading") {
            return (
                <div className="content">
                    <Loader active/>;
                </div>
            );
        } else if (loadingStatus.key == "error") {
            return (
                <div className="content">
                    <Message negative content={loadingStatus.message}/>
                </div>
            );
        } else {
            const lever = loadingStatus.lever;
            return (
                <div className="content">
                    <Header content={lever.name}/>
                    {lever.actions.map(action => (
                        <Button key={action.id}
                                primary
                                content={action.name}
                                loading={actionStatus.key == "loading" && actionStatus.actionId == action.id}
                                style={{marginTop: "5px"}}
                                onClick={() => this.executeAction(action)}/>
                    ))}
                    {actionStatus.key == "error" ? (
                        <Message negative
                                 content={actionStatus.message}
                                 onDismiss={() => this.setState({actionStatus: {key: "ready"}})}/>
                    ) : null}
                </div>
            );
        }
    }
}
