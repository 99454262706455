import {ParseResult, ParseUtils} from "bryx-cereal";

export class LeverAction {
    private constructor(public id: string,
                        public name: string,
                        public description: string) {
    }

    static parse(o: any): ParseResult<LeverAction> {
        try {
            return ParseUtils.parseSuccess(new LeverAction(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getString(o, "description"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<LeverAction>(`Invalid LeverAction: ${e.message}`);
        }
    }
}

export class Lever {
    private constructor(public id: string,
                        public name: string,
                        public actions: LeverAction[]) {
    }

    static parse(o: any): ParseResult<Lever> {
        try {
            return ParseUtils.parseSuccess(new Lever(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getArrayOfSubobjects(o, "actions", LeverAction.parse, "warn"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Lever>(`Invalid Lever: ${e.message}`);
        }
    }
}
